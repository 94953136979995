.NavigationDropdown-cvaDropdownItem:hover
  + .NavigationDropdown-cvaDropdownItem
  .NavigationDropdown-cvaDropdownItemInner,
.NavigationDropdown-cvaDropdownItem.active
  + .NavigationDropdown-cvaDropdownItem
  .NavigationDropdown-cvaDropdownItemInner {
  border-color: transparent;
}

.NavigationDropdown-cvaDropdownItem.active
  .NavigationDropdown-cvaDropdownItemInner {
  border-color: transparent;
}
