.input {
  display: none;
  &:checked {
    ~ .checkbox {
      @apply bg-cPurple700;

      & > svg {
        @apply opacity-100 text-cWhite;
      }
    }
  }
}

.checkbox {
  @apply w-2.4 h-2.4 rounded-[50%] bg-white relative overflow-hidden;

  & > svg {
    @apply opacity-0 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2;
  }
}
