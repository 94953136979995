.input {
  @apply hidden;
}

.switcher {
  @apply bg-cGray900 rounded-[10rem] p-0.4 w-6.4 h-4.8 duration-300 relative cursor-pointer;
}
.handler {
  @apply h-4 w-4 rounded-full bg-white [&>svg]:fill-cPurple700 absolute left-0.4 top-0.4 duration-300 flex justify-center items-center;
}

.input:checked {
  ~ .switcher {
    @apply bg-cPurple700;
    .handler {
      @apply left-[calc(100%-4.4rem)];
    }
  }
}
